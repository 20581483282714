
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Card, Grid } from '@mui/material';
import IndefiniteLoadingSpinner from "../../components/loading-spinner";
import Layout from "../../components/layout";
import { useEffect, useState } from 'react';
import { LicensureResearchDataListRecord } from '../../api/models';
import { retrieveLicensureResearchDataList } from '../../api/research-data-service';
import { formatDate } from '../../common/utils';

const WIDGET_STATES = {
    LOADING_DATA: 0,
    LOADED_DATA: 1,
  }
  
const LicensureResearchDataListView: React.FC = () => {
    const [widgetState, setWidgetState] = useState<number>(WIDGET_STATES.LOADING_DATA);
    const [resumeKey ] = useState<string>("");
    const [researchDataList, setResearchDataList] = useState<LicensureResearchDataListRecord | null>(null);

    useEffect(() => {
        retrieveLicensureResearchDataList(null)
          .then(apiResult => {
            setWidgetState(WIDGET_STATES.LOADED_DATA);
    
            if (!apiResult) {
              setResearchDataList(null);
              return;
            }
    
            if ((apiResult?.ok) && (apiResult.data)) {
                setResearchDataList(apiResult.data);
            } else {
              // TODO: Handle error
              console.error("Error fetching research data:", apiResult?.error?.message);
              setResearchDataList(null);
            }
          })
          .catch(error => {
            console.error("Error fetching research data:", error);
          });
        return () => { };
      }, [resumeKey]);



    if (widgetState === WIDGET_STATES.LOADING_DATA) {
        return (
          <IndefiniteLoadingSpinner />
        );
    }

    return (
        <>
      <Layout pageName="research-data">
        <Grid container lg={6} md={6} xs={10} className="research-data-container">
          <Grid item xs={12}>
            <Card>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Submission ID</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>School</TableCell>
                        <TableCell>Program</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Meets Requirements?</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {researchDataList?.items.map(item => (
                        <TableRow key={item.id}>
                            <TableCell>
                                <Link href={`/research-data/${item.id}`} rel="noopener noreferrer">
                                    {item.id}
                                </Link>
                            </TableCell>
                            <TableCell>{formatDate(item.lastUpdatedDate)}</TableCell>
                            <TableCell>{item.program.providerOrg.name}</TableCell>
                            <TableCell>{item.program.programName}</TableCell>
                            <TableCell>{item.stateLicensureStatus.state.code}</TableCell>
                            <TableCell>{item.stateLicensureStatus.meetRequirements ? "Yes" : "No"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </Card>
        </Grid>
        </Grid>
        </Layout>
        </>
    );
};

export default LicensureResearchDataListView;