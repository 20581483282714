import { Typography } from "@mui/material";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import { organizationsData } from "../../api/models";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EditIcon from "@mui/icons-material/Edit";

export const OrganizationDataColumns = (
  onClick: (action: string, org: organizationsData) => void,
): GridColDef[] => {
  return [
    {
      field: "orgId",
      minWidth: 120,
      maxWidth: 300,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <Typography variant="h4">ID</Typography>
          </div>
        );
      },
    },
    {
      field: "orgName",
      minWidth: 200,
      maxWidth: 350,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <Typography variant="h4">Name</Typography>
          </div>
        );
      },
    },
    {
      field: "orgType",
      minWidth: 100,
      maxWidth: 300,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <Typography variant="h4">Type</Typography>
          </div>
        );
      },
    },
    {
      field: "Update Org",
      minWidth: 50,
      maxWidth: 100,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            Edit
          </div>
        );
      },
      renderCell: (params: GridRenderCellParams<organizationsData>) => {
        return (
          <div
            className="org-edit-button"
            id={params.row.orgId}
            onClick={() => onClick("org-edit", params.row)}
          >
            <EditIcon />
          </div>
        );
      },
    },
    {
      field: "Add Admin",
      minWidth: 50,
      maxWidth: 100,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div
            style={{
              lineHeight: "normal",
              fontWeight: "bold",
              width: "100%",
              justifyItems: "center",
              display: "flex",
            }}
          >
            <SettingsIcon />
          </div>
        );
      },
      renderCell: (params: GridRenderCellParams<organizationsData>) => {
        return (
          <div
            className="org-view-button"
            id={params.row.orgId}
            onClick={() => onClick("add-admin", params.row)}
          >
            <PersonAddAltIcon />
          </div>
        );
      },
    },
  ];
};
