// material-ui
import { Grid, Typography } from "@mui/material";
import type {
  GridRowsProp,
  GridColDef,
  GridEventListener,
  GridRowIdGetter,
} from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import type { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import "./style.scss";

type DataTableProps = {
  tableHeading?: string;
  downloadURL?: string;
  rowHeight?: number;
  pageSize?: number;
  hideColumnsHeader?: boolean;
  hideFooter?: boolean;
  hideFooterPagination?: boolean;
  disablePagination?: boolean;
  rows: GridRowsProp;
  columns: GridColDef[];
  getRowId: GridRowIdGetter;
  initialState?: GridInitialStateCommunity;
  onCellClick?: GridEventListener<"cellClick">;
};

export default function DataTable(props: DataTableProps) {
  const theme = useTheme();

  const downloadData = () => {
    if (!props.downloadURL) {
      return;
    }
    window.open(props.downloadURL);
  };

  return (
    <Grid item xs={12}>
      <div className="table-wrp">
        {props.tableHeading && props.tableHeading !== "" && (
          <div className="table-heading">
            <Typography variant="h3">{props.tableHeading}</Typography>
          </div>
        )}
        {props.downloadURL && props.downloadURL !== "" && (
          <Button
            variant="contained"
            className="download-icon"
            onClick={downloadData}
          >
            <DownloadIcon />
          </Button>
        )}
        <div className="table-content">
          <DataGrid
            initialState={{
              ...props.initialState,
              pagination: {
                paginationModel: { pageSize: props.pageSize || 100, page: 0 },
              },
            }}
            columnHeaderHeight={props.hideColumnsHeader ? 0 : 56}
            hideFooter={props.hideFooter}
            hideFooterPagination={props.hideFooterPagination}
            rows={props.rows}
            disableColumnMenu={true}
            getRowId={props.getRowId}
            columns={props.columns}
            onCellClick={props.onCellClick}
            getCellClassName={(params) => `column-${params.field}`}
            pagination={props.disablePagination ? undefined : true}
            autoHeight={true}
            rowHeight={props.rowHeight}
            disableColumnFilter={true}
            classes={{
              sortIcon: "white-icon",
              menuIconButton: "white-icon",
            }}
            sx={{
              border: 0,
              backgroundColor: theme.palette.background.paper,
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              "& .MuiDataGrid-cell": {
                backgroundColor: theme.palette.background.paper,
                borderColor: theme.palette.primary.main,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
                color: theme.palette.custom.tableHead,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.paper,
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              },
              "& .MuiDataGrid-selectedRowCount": {
                color: theme.palette.background.paper,
              },
            }}
          />
        </div>
      </div>
    </Grid>
  );
}
