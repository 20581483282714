/**
 * Parses a string value and returns its boolean representation.
 * 
 * @param value - The string value to parse. Can be `undefined` or `null`.
 * @returns `true` if the value represents a truthy string ("true", "t", "yes", "y", "1"),
 *          `false` otherwise (including `undefined` and `null`).
 */
function parseBoolean(value: string | undefined | null): boolean {
    if (value === undefined || value === null) {
      return false;
    }
  
    switch (value.toLowerCase().trim()) {
      case "true":
      case "t":
      case "yes":
      case "y":
      case "1":
        return true;

      case "false":
      case "f":
      case "no":
      case "n":
      case "0":
        return false;

      default:
        return false;
    }
  }
  
export { parseBoolean };
