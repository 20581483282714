import { Box, Modal } from "@mui/material";
import { updateOrganization } from "../../api/organization-service";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import OrganizationForm, {
  OrgFormData,
} from "../../components/organization-form";
import { organizationsData } from "../../api/models";

type UpdateOrganizationProps = {
  organization: organizationsData;
  open: boolean;
  close: () => void;
  success?: () => void;
};
const UpdateOrganization = (props: UpdateOrganizationProps) => {
  const theme = useTheme();

  const [enableCreateButton, setEnableCreateButton] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [responseMsg, setResponseMsg] = useState("");

  const SubmitOrganizationData = (formData: OrgFormData) => {
    setErrorMsg("");
    setResponseMsg("");

    setEnableCreateButton(false);

    updateOrganization(props.organization.orgId, {
      orgName: formData.orgName,
      orgType: formData.orgType,
      domainName: formData.domainName,
      address: formData.address ? formData?.address : "",
      orgStatus: "active", // as of now keep this as default value
    })
      .then((resp) => {
        setEnableCreateButton(true);
        if (resp?.message) {
          setResponseMsg(resp.message);
          props.success?.();
        } else {
          if (resp?.error?.message) setErrorMsg(resp?.error?.message);
        }
      })
      .catch((er) => {
        console.log("SubmitOrganizationData:", er);
        setEnableCreateButton(true);
      });
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: theme.palette.background.paper,
    border: "1px solid rgba(0, 0, 0, 0.3)",
    borderRadius: "10px",
  };

  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <OrganizationForm
          defaultValues={{
            orgName: props.organization.orgName,
            domainName: props.organization.domainName,
            orgType: props.organization.orgType
              ? props.organization.orgType
              : "",
            address: props.organization.address
              ? props.organization.address
              : "",
          }}
          SubmitOrganizationData={SubmitOrganizationData}
          enableSubmitButton={enableCreateButton}
          errorMsg={errorMsg}
          responseMsg={responseMsg}
          handleClose={() => {
            setErrorMsg("");
            setResponseMsg("");
            props.close();
          }}
        />
      </Box>
    </Modal>
  );
};

export default UpdateOrganization;
