export function storeData(key: string, data: any) {
  const dataString = JSON.stringify(data);
  localStorage.setItem(key, dataString);
}

interface userRole {
  roleId: number;
  roleName: string;
}
interface UserData {
  accessToken: string;
  userData: {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    userStatus: string;
    userRoles: userRole[];
  };
}

export function retrieveUserData(key: string): UserData | null {
  const dataString = localStorage.getItem(key);
  if (dataString) {
    const data = JSON.parse(dataString) as UserData;
    return data;
  }
  return null;
}

export function formatDate(date: Date): string {
  return date.toLocaleDateString(undefined, { day: '2-digit', month: 'short', year: 'numeric' });
}
