import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { createOrganizationAdmin } from "../../api/organization-service";
import { organizationsData } from "../../api/models";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextInput from "../../components/custom-text-input";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import CloseIcon from "@mui/icons-material/Close";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PasswordIcon from "@mui/icons-material/Password";

type FormInputs = {
  firstName: string;
  lastName?: string;
  emailId: string;
  phone?: string;
  password: string;
  rePassword: string;
};

type AddOrganizationAdminProps = {
  organization: organizationsData;
  open: boolean;
  close: () => void;
};
const AddOrganizationAdmin = (props: AddOrganizationAdminProps) => {
  const theme = useTheme();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(
      yup.object({
        firstName: yup.string().required("Please enter the first name."),
        emailId: yup
          .string()
          .email("The email address provided is not valid.")
          .required("Please enter email address."),
        lastName: yup.string().optional(),
        phone: yup
          .string()
          .matches(/^(\s*|\d+)$/, "Phone number must contain only numbers")
          .max(16, "Please enter a valid country code (eg: 91).")
          .optional(),
        password: yup.string().required("Please enter the password."),
        rePassword: yup
          .string()
          .required("Please enter the password.")
          .oneOf([yup.ref("password")], "Passwords must match"),
      }),
    ),
  });

  const [enableCreateButton, setEnableCreateButton] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [responseMsg, setResponseMsg] = useState("");

  useEffect(() => {
    setErrorMsg("");
    setResponseMsg("");
    // reset(); // TODO: Revisit: lint error "React Hook useEffect has a missing dependency: 'reset'. Either include it or remove the dependency array  react-hooks/exhaustive-deps"
  }, []);

  const SubmitOrganizationAdminData = (formData: FormInputs) => {
    setErrorMsg("");
    setResponseMsg("");

    setEnableCreateButton(false);

    if (formData.password !== formData.rePassword) {
    }

    createOrganizationAdmin({
      orgId: props.organization.orgId,
      firstName: formData.firstName,
      lastName: formData.lastName ? formData.lastName : "",
      emailId: formData.emailId,
      phone: formData.phone ? formData.phone : "",
      password: formData.password,
    })
      .then((resp) => {
        setEnableCreateButton(true);

        if (resp?.message) {
          setResponseMsg(resp.message);
        } else {
          if (resp?.error?.message) setErrorMsg(resp?.error?.message);
        }
      })
      .catch((er) => {
        console.log("SubmitOrganizationAdminData:", er);
        setEnableCreateButton(true);
      });
  };

  const handleClose = () => {
    setErrorMsg("");
    setResponseMsg("");
    reset();
    props.close();
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: theme.palette.background.paper,
    border: "1px solid rgba(0, 0, 0, 0.3)",
    borderRadius: "10px",
  };

  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid item className="create-admin-input-wrp">
          <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
            Create Admin
          </Typography>

          <form onSubmit={handleSubmit(SubmitOrganizationAdminData)}>
            <Typography variant="h4" id="organization-name-title">
              Organization:
              <label>{props.organization.orgName}</label>
            </Typography>

            <Box className="input-field" sx={{ display: "flex" }}>
              <CustomTextInput
                label="First name*"
                icon={<AccountBoxIcon />}
                maxLength={50}
                error={errors?.firstName?.message ? true : false}
                helperText={
                  errors?.firstName?.message ? errors.firstName.message : ""
                }
                register={register("firstName")}
              ></CustomTextInput>
            </Box>

            <Box className="input-field" sx={{ display: "flex" }}>
              <CustomTextInput
                label="Last name"
                icon={<AccountBoxIcon />}
                maxLength={50}
                error={errors?.lastName?.message ? true : false}
                helperText={
                  errors?.lastName?.message ? errors.lastName.message : ""
                }
                register={register("lastName")}
              ></CustomTextInput>
            </Box>

            <Box className="input-field" sx={{ display: "flex" }}>
              <CustomTextInput
                label="Email id*"
                icon={<AlternateEmailIcon />}
                maxLength={50}
                error={errors?.emailId?.message ? true : false}
                helperText={
                  errors?.emailId?.message ? errors.emailId.message : ""
                }
                register={register("emailId")}
              ></CustomTextInput>
            </Box>

            <Box className="input-field" sx={{ display: "flex" }}>
              <CustomTextInput
                label="Phone"
                type="text"
                icon={<ContactPhoneIcon />}
                maxLength={16}
                error={errors?.phone?.message ? true : false}
                helperText={errors?.phone?.message ? errors.phone.message : ""}
                register={register("phone")}
              ></CustomTextInput>
            </Box>

            <Box className="input-field" sx={{ display: "flex" }}>
              <CustomTextInput
                label="Password*"
                type="password"
                icon={<PasswordIcon />}
                maxLength={250}
                error={errors?.password?.message ? true : false}
                helperText={
                  errors?.password?.message ? errors.password.message : ""
                }
                register={register("password")}
              ></CustomTextInput>
            </Box>
            <Box className="input-field" sx={{ display: "flex" }}>
              <CustomTextInput
                label="Re-enter password*"
                type="password"
                icon={<PasswordIcon />}
                maxLength={250}
                error={errors?.rePassword?.message ? true : false}
                helperText={
                  errors?.rePassword?.message ? errors.rePassword.message : ""
                }
                register={register("rePassword")}
              ></CustomTextInput>
            </Box>

            {errorMsg ? (
              <Typography color="error" className="error-message">
                {errorMsg}
              </Typography>
            ) : (
              <></>
            )}
            {responseMsg && !errorMsg ? (
              <Typography color="green" className="response-message">
                {responseMsg}
              </Typography>
            ) : (
              <></>
            )}

            <Grid item xs={12}>
              <Button
                className="submit-button"
                disabled={!enableCreateButton}
                variant="contained"
                type="submit"
                startIcon={<AddBusinessIcon />}
              >
                {enableCreateButton ? "Create" : "..."}
              </Button>
              <Button
                className="close-button"
                disabled={!enableCreateButton}
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={() => handleClose()}
              >
                Close
              </Button>
            </Grid>
          </form>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddOrganizationAdmin;
