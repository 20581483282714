import { getData, postData, putData } from "./api";
import {
  createOrganizationAdminApiRequest,
  createOrganizationAdminApiResponse,
  createOrganizationAdminRequestData,
  createOrganizationApiRequest,
  createOrganizationApiResponse,
  createOrganizationRequestData,
  organizationsGetApiResponse,
  organizationsResponseData,
  updateOrganizationApiRequest,
  updateOrganizationApiResponse,
  updateOrganizationRequestData,
} from "./models";

export const createOrganization = async (
  data: createOrganizationRequestData,
): Promise<createOrganizationApiResponse | null> => {
  try {
    let url = "/api/v1/organizations";

    const apiReq: createOrganizationApiRequest = {
      data: data,
    };

    const apiResponse: createOrganizationApiResponse =
      await postData<createOrganizationApiResponse>(url, apiReq);

    return apiResponse;
  } catch (error) {
    console.error("Error fetching response data:", error);
    return null;
  }
};

export const updateOrganization = async (
  orgId: string,
  data: updateOrganizationRequestData,
): Promise<updateOrganizationApiResponse | null> => {
  try {
    let url = "/api/v1/organizations/" + orgId;

    const apiReq: updateOrganizationApiRequest = {
      data: data,
    };

    const apiResponse: updateOrganizationApiResponse =
      await putData<updateOrganizationApiResponse>(url, apiReq);

    return apiResponse;
  } catch (error) {
    console.error("Error fetching response data:", error);
    return null;
  }
};

export const getOrganizationsData = async (
  query: string,
): Promise<organizationsResponseData | null> => {
  try {
    let url = "/api/v1/organizations";

    const organizationApiResponse: organizationsGetApiResponse =
      await getData<organizationsGetApiResponse>(url);

    if (!organizationApiResponse || !organizationApiResponse.data) {
      console.log("Invalid response from server");
      return null;
    }

    return organizationApiResponse.data;
  } catch (error) {
    console.error("Error fetching organization data:", error);
    return null;
  }
};

export const createOrganizationAdmin = async (
  data: createOrganizationAdminRequestData,
): Promise<createOrganizationAdminApiResponse | null> => {
  try {
    let url = "/api/v1/organizations/admin";

    const apiReq: createOrganizationAdminApiRequest = {
      data: data,
    };

    const apiResponse: createOrganizationAdminApiResponse =
      await postData<createOrganizationAdminApiResponse>(url, apiReq);

    return apiResponse;
  } catch (error) {
    console.error("Error fetching login response data:", error);
    return null;
  }
};
