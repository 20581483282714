import { Theme, createTheme } from "@mui/material";

// Extend the existing MUI Palette interface
declare module "@mui/material/styles" {
  interface Palette {
    custom: Palette["text"] & {
      tableHead: string; // Add tableHead to the Palette interface
    };
  }

  interface PaletteOptions {
    custom?: PaletteOptions["text"] & {
      tableHead?: string; // Make tableHead optional in PaletteOptions
    };
  }
}

export const AppLightTheme: Theme = createTheme({
  palette: {
    primary: {
      main: "#001e60",
    },
    secondary: {
      main: "#001e60",
    },
    background: {
      default: "rgb(243,252,255)",
      paper: "rgb(255,252,255)",
    },
    text: {
      primary: "#001e60",
    },
    custom: {
      tableHead: "#ffff",
    },
  },
  typography: {
    fontWeightBold: 10,
    body1: {
      fontSize: 15,
      fontWeight: "lighter",
    },
    h2: {
      fontSize: 25,
      fontWeight: "bolder",
    },
    h3: {
      fontSize: 18,
      fontWeight: "bolder",
    },
    h4: {
      fontSize: 13,
      fontWeight: "bolder",
    },
    h5: {
      fontSize: 11,
      fontWeight: "bolder",
    },
  },
});

export const AppDarkTheme: Theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#cbcfd7",
    },
    secondary: {
      main: "#122136",
    },
    background: {
      default: "rgb(33,37,39)",
      paper: "rgb(41,44,49)",
    },
    custom: {
      tableHead: "#ffff",
    },
  },
  typography: {
    fontWeightBold: 10,
    body1: {
      fontSize: 15,
      fontWeight: "lighter",
    },
    h2: {
      fontSize: 25,
      fontWeight: "bolder",
    },
    h3: {
      fontSize: 18,
      fontWeight: "bolder",
    },
    h4: {
      fontSize: 13,
      fontWeight: "bolder",
    },
    h5: {
      fontSize: 11,
      fontWeight: "bolder",
    },
  },
});
