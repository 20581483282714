export type errorResponse = {
  message: string;
};

// api response models for program suggetions
export type programsSuggestionApiResponse = {
  status: string;
  data: programsSuggestionData;
};

export type programsSuggestionData = {
  programSuggestions: programSuggestion[];
};

export type programSuggestion = {
  programName: string;
  programCode: string;
};

export type programSuggestionSearchResult = {
  id: string;
  value: string;
};

// api response models for program list
export type programsApiResponse = {
  status: string;
  data: programsResponseData;
};

export type programsResponseData = {
  programs: programsData[];
  stateList: stateList[];
};

export type programsData = {
  programName: string;
  programCode: string;
  practicingState: string;
  programStateData: { [key: string]: programStateEigibility };
};

export type programStateEigibility = {
  practicingState: string;
  stateCode: string;
  eligibility: string;
};

export type stateList = {
  stateName: string;
  stateCode: string;
};

export type StudentSuggestion = {
  id: string;
  value: string;
};

export type StudentsData = {
  id: number;
  name: string;
  email: string;
  state: string;
  program: string;
  disclosure: string;
  date: string;
};

// login api request models
export type loginApiRequest = {
  data: loginApiRequestData;
};

export type loginApiRequestData = {
  email: string;
  password: string;
};

// api response models login
export type loginApiResponse = {
  status: string;
  data: loginResponseData;
  error: errorResponse;
};

type userData = {
  id: string;
  name: string;
  email: string;
};

export type loginResponseData = {
  accessToken: string;
  userData: userData;
};

// create organization api request models
export type createOrganizationApiRequest = {
  data: createOrganizationRequestData;
};
export type createOrganizationRequestData = {
  orgName: string;
  orgType: string;
  domainName: string;
  address: string;
};

// api response models create organization
export type createOrganizationApiResponse = {
  status: string;
  message: string;
  error: errorResponse;
};

// update organization api request models
export type updateOrganizationApiRequest = {
  data: createOrganizationRequestData;
};
export type updateOrganizationRequestData = {
  orgName: string;
  orgType: string;
  domainName: string;
  address: string;
  orgStatus: string;
};

// api response models create organization
export type updateOrganizationApiResponse = {
  status: string;
  message: string;
  error: errorResponse;
};

// api response models for program list
export type organizationsGetApiResponse = {
  status: string;
  data: organizationsResponseData;
};

export type organizationsResponseData = {
  organizations: organizationsData[];
};

export type organizationsData = {
  orgId: string;
  orgName: string;
  domainName: string;
  orgType?: string;
  address?: string;
  orgStatus: string;
  createdAt: string;
};

// create organization api request models
export type createOrganizationAdminApiRequest = {
  data: createOrganizationAdminRequestData;
};
export type createOrganizationAdminRequestData = {
  orgId: string;
  firstName: string;
  lastName?: string;
  emailId: string;
  phone?: string;
  password: string;
};

// api response models create organization
export type createOrganizationAdminApiResponse = {
  status: string;
  message: string;
  error: errorResponse;
};


export class ProgramStateLicensureStatusRecord {
  id: string;
  lastUpdatedDate: Date;
  version: number;
  // submissionDate: string;
  program: Program;
  stateLicensureStatus: StateLicensureStatus;
  referenceData: StateLicensureReferenceData[];

  constructor(
    id: string,
    lastUpdatedDate: Date,
    version: number,
    program: Program,
    stateLicensureStatus: StateLicensureStatus,
    referenceData: StateLicensureReferenceData[]
  ) {
    this.id = id;
    this.lastUpdatedDate = lastUpdatedDate;
    this.version = version;
    this.program = program;
    this.stateLicensureStatus = stateLicensureStatus;
    this.referenceData = referenceData;
  }
}

type Program = {
  providerOrg: Organization;
  programName: string;
};

type State = {
  name: string;
  code: string;
}

type Organization = {
  name: string;
  state: State;
};

type StateLicensureStatus = {
  state: State;
  meetRequirements: boolean;
};

type StateLicensureReferenceData = {
  url: string;
  note: string;
}

export class LicensureResearchDataReviewFeedback {
  submissionId: string;
  approval: string; // approved, rejected, review-later
  comment: string

  constructor(submissionId: string, approval: string, comment: string) {
    this.submissionId = submissionId;
    this.approval = approval;
    this.comment = comment;
  }
}


export class LicensureResearchDataRecord {
  id: string;
  lastUpdatedDate: Date;
  version: number;
  program: Program;
  stateLicensureStatus: StateLicensureStatus;

  constructor(
    id: string,
    lastUpdatedDate: Date,
    version: number,
    program: Program,
    stateLicensureStatus: StateLicensureStatus,

  ) {
    this.id = id;
    this.lastUpdatedDate = lastUpdatedDate;
    this.version = version;
    this.program = program;
    this.stateLicensureStatus = stateLicensureStatus;
  }
}

export class LicensureResearchDataListRecord {
  items: LicensureResearchDataRecord[] = [];

  constructor(items: LicensureResearchDataRecord[] = []) {
    this.items = items;
  }
}
