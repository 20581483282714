import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./pages/login/index";
import Programs from "./pages/programs/index";
import LicensureResearchData from "./pages/research-data/detail-view";
import LicensureResearchDataListView from "./pages/research-data/list-view";
import Students from "./pages/students";
import CreateOrganization from "./pages/create-organization";
import { ThemeContextProvider } from "./theme-context";
import OrganizationList from "./pages/organization-list";

function App() {
  return (
    <ThemeContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Programs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/research-data" element={<LicensureResearchDataListView />} />
          <Route path="/research-data/:submissionId" element={<LicensureResearchData />} />
          <Route path="/students" element={<Students />} />
          <Route path="/create/organization" element={<CreateOrganization />} />
          <Route path="/organization" element={<OrganizationList />} />
        </Routes>
      </BrowserRouter>
    </ThemeContextProvider>
  );
}
export default App;
