import "./style.scss";
import { Button, Grid, Typography } from "@mui/material";
import DataTable from "../../components/data-table";
import Layout from "../../components/layout";
import { useEffect, useState } from "react";
import { organizationsData } from "../../api/models";
import { getOrganizationsData } from "../../api/organization-service";
import { OrganizationDataColumns } from "./organization-table-utils";
import AddOrganizationAdmin from "./add-admin-modal";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useNavigate } from "react-router-dom";
import { retrieveUserData } from "../../common/utils";
import { USER_LOGIN_DATA_STORAGE_KEY } from "../../common/const";
import UpdateOrganization from "./update-organization-modal";

function OrganizationList(props: any) {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState<organizationsData[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<organizationsData>({
    orgId: "",
    createdAt: "",
    domainName: "",
    orgName: "",
    orgStatus: "",
  });

  const [showOrgUpdateModal, setShowOrgUpdateModal] = useState(false);
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);

  useEffect(() => {
    const user = retrieveUserData(USER_LOGIN_DATA_STORAGE_KEY);
    if (!user) window.location.href = "/login";

    console.log("USER LOGGED IN :: ", user?.userData);
    getOrganizations("");
  }, []);

  const getOrganizations = async (query: string) => {
    const organizationsRespData = await getOrganizationsData(query);

    if (organizationsRespData?.organizations) {
      setOrganizations(organizationsRespData.organizations);
    } else {
      setOrganizations([]);
    }
  };

  return (
    <Layout pageName="organization-list">
      <Grid item className="organization-list-container">
        <Typography variant="h2">Organizations</Typography>

        <AddOrganizationAdmin
          organization={selectedOrg}
          open={showAddAdminModal}
          close={() => {
            setShowAddAdminModal(false);
          }}
        />
        <UpdateOrganization
          open={showOrgUpdateModal}
          success={() => {
            getOrganizations("");
          }}
          close={() => {
            setShowOrgUpdateModal(false);
          }}
          organization={selectedOrg}
        />
        <Grid
          className={
            "organization-options-wrp" +
            (organizations?.length ? "" : " center")
          }
        >
          <Button
            className="submit-button"
            variant="outlined"
            type="submit"
            startIcon={<AddBusinessIcon />}
            onClick={() => navigate(`/create/organization`)}
          >
            Add
          </Button>
        </Grid>
        {organizations?.length ? (
          <Grid item className="table-container">
            <DataTable
              getRowId={(row) => row.orgId}
              rows={organizations}
              columns={OrganizationDataColumns((action, org) => {
                setSelectedOrg(org);

                switch (action) {
                  case "org-edit":
                    setShowOrgUpdateModal(true);
                    break;

                  case "add-admin":
                    setShowAddAdminModal(true);
                    break;
                }
              })}
              hideFooterPagination={true}
              disablePagination={true}
              hideFooter={true}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Layout>
  );
}

export default OrganizationList;
