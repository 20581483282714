import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { initMockApi } from "./api-mock";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const apiClient: AxiosInstance = newAxiosInstance();

/**
 * Creates a new Axios instance with predefined configuration.
 *
 * @returns {AxiosInstance} A new Axios instance configured with the base URL and credentials.
 */
function newAxiosInstance(): AxiosInstance {
  let apiClient = axios.create({
    baseURL: baseURL,
    withCredentials: true,
  });

  initMockApi(apiClient);

  return apiClient;
}


function getApiClient(): AxiosInstance {
  return apiClient;
}

// Function to handle API errors
const handleApiError = <T>(error: AxiosError | any): any => {
  if (axios.isAxiosError(error) && error?.response) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }

    if (error.response?.data) return error.response.data;
  }

  console.error("Unexpected Error:", error);
  return ApiResponse.fromErrorMessage<T>("Unexpected Error: " + error);
};

// Generic function for making GET requests
const getData = async <T>(url: string): Promise<any> => {
  try {
    const response: AxiosResponse<T> = await apiClient.get<T>(`${baseURL}${url}`, {
      withCredentials: true, // Allow cookies to be sent and stored
    });

    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// Generic function for making POST requests
const postData = async <T>(url: string, data?: any): Promise<any> => {
  try {
    const response: AxiosResponse<T> = await apiClient.post<T>(
      `${baseURL}${url}`,
      data,
      {
        withCredentials: true, // Allow cookies to be sent and stored
      },
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// Generic function for making PUT requests
const putData = async <T>(url: string, data: any): Promise<any> => {
  try {
    const response: AxiosResponse<T> = await axios.put<T>(
      `${baseURL}${url}`,
      data,
      {
        withCredentials: true, // Allow cookies to be sent and stored
      },
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// Generic function for making DELETE requests
const deleteData = async (url: string): Promise<void> => {
  try {
    await axios.delete(`${baseURL}${url}`, {
      withCredentials: true, // Allow cookies to be sent and stored
    });
  } catch (error) {
    return handleApiError(error);
  }
};

// Generic function for making PATCH requests
const patchData = async <T>(url: string, data: T): Promise<any> => {
  try {
    const response: AxiosResponse<T> = await axios.patch<T>(
      `${baseURL}${url}`,
      data,
      {
        withCredentials: true, // Allow cookies to be sent and stored
      },
    );
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

type ApiError = {
  message: string;
};

const createApiRequestData = function<T>(data: T): { data: T } {
  return { data: data }; 
}

type ApiResponseData<T> = {
  status: string;
  data: T | null;
  error: ApiError | null;
}
class ApiResponse<T> implements ApiResponseData<T> {
  status: string;
  data: T | null;
  error: ApiError | null;

  constructor(responseData: ApiResponseData<T>) {
    this.status = responseData.status;
    this.data = responseData.data;
    this.error = responseData.error;
  }

  static fromErrorMessage<T>(message: string): ApiResponse<T> {
    return new ApiResponse<T>({
      status: "error",
      data: null,
      error: {
        message: message,
      },
    });
  }

  static fromApiResponse<T>(resp: ApiResponseData<T>): ApiResponse<T> {
    return new ApiResponse<T>(resp);
  }

  isSuccess(): boolean {
    return this.status === "success";
  }
}


export { getData, postData, putData, deleteData, patchData, getApiClient, ApiResponse, createApiRequestData };
