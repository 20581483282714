import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { UseFormRegisterReturn } from "react-hook-form";

interface Options {
  label: string;
  value: any;
}

interface CustomSelectInputProps {
  label: string;
  value?: string;
  defaultValue?: string;
  options?: Options[];
  required?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  icon?: React.ReactNode;
  error?: boolean;
  helperText?: string;
  onChange?: (event: SelectChangeEvent) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  register?: UseFormRegisterReturn;
}

const CustomSelectInput: React.FC<CustomSelectInputProps> = ({
  label,
  value,
  defaultValue,
  options,
  required,
  fullWidth,
  multiline,
  rows,
  icon,
  error,
  helperText,
  onChange,
  register,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        width: "100%",
        marginTop: "10px",
      }}
    >
      <div className={"input-icon " + (error ? "field-error" : "")}>{icon}</div>
      <FormControl
        variant="standard"
        sx={{
          display: "flex",
          minWidth: "80%",
        }}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          label={label}
          required={required}
          error={error}
          fullWidth={fullWidth}
          onKeyDown={() => (props?.onKeyDown ? props.onKeyDown : "")}
          sx={{
            "&.Mui-error fieldset.MuiOutlinedInput-notchedOutline": {
              borderColor: "red",
            },
          }}
          {...register}
        >
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {error ? (
          <Typography
            color="error"
            sx={{
              fontSize: "12px",
            }}
          >
            {helperText ? helperText : "Please select a value."}
          </Typography>
        ) : (
          <></>
        )}
      </FormControl>
    </Box>
  );
};

export default CustomSelectInput;
