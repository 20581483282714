import "./style.scss";
import { Box, Button, Grid, Typography } from "@mui/material";
import CustomTextInput from "../../components/custom-text-input";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BusinessIcon from "@mui/icons-material/Business";
import DnsIcon from "@mui/icons-material/Dns";
import WorkIcon from "@mui/icons-material/Work";
import CloseIcon from "@mui/icons-material/Close";
import CustomSelectInput from "../../components/custom-select-input";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export type OrgFormData = {
  orgName: string;
  domainName: string;
  orgType: string;
  address?: string;
};

type OrganizationFormProps = {
  enableSubmitButton: boolean;
  errorMsg: string;
  responseMsg: string;
  defaultValues?: OrgFormData;
  SubmitOrganizationData: (formData: OrgFormData) => void;
  handleClose?: () => void;
};

function OrganizationForm(props: OrganizationFormProps) {
  const availableOrgType = [{ label: "School", value: "school" }];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OrgFormData>({
    defaultValues: props.defaultValues,
    resolver: yupResolver(
      yup.object({
        orgName: yup.string().required("Please enter the organization name."),
        orgType: yup.string().required("Please select a type of organization."),
        domainName: yup.string().required("Please enter the domain name."),
        address: yup.string().optional(),
      }),
    ),
  });

  return (
    <Grid item className="organization-input-wrp">
      <Typography variant="h3">
        {props.defaultValues ? "Update Organization" : "Create Organization"}
      </Typography>
      <form onSubmit={handleSubmit(props.SubmitOrganizationData)}>
        <Box className="input-field" sx={{ display: "flex" }}>
          <CustomTextInput
            label="Organization Name*"
            icon={<WorkIcon />}
            maxLength={50}
            error={errors?.orgName?.message ? true : false}
            helperText={errors?.orgName?.message ? errors.orgName.message : ""}
            register={register("orgName")}
          ></CustomTextInput>
        </Box>

        <Box className="input-field" sx={{ display: "flex" }}>
          <CustomSelectInput
            defaultValue={props.defaultValues?.orgType}
            label="Organization Type*"
            icon={<ManageAccountsIcon />}
            error={errors?.orgType?.message ? true : false}
            helperText={errors?.orgType?.message ? errors.orgType.message : ""}
            options={availableOrgType}
            register={register("orgType")}
          />
        </Box>

        <Box className="input-field" sx={{ display: "flex" }}>
          <CustomTextInput
            label="Domain Name*"
            icon={<DnsIcon />}
            maxLength={100}
            error={errors?.domainName?.message ? true : false}
            helperText={
              errors?.domainName?.message ? errors.domainName.message : ""
            }
            register={register("domainName")}
          ></CustomTextInput>
        </Box>

        <Box className="input-field" sx={{ display: "flex" }}>
          <CustomTextInput
            label="Address"
            icon={<BusinessIcon />}
            maxLength={500}
            error={errors?.address?.message ? true : false}
            helperText={errors?.address?.message ? errors.address.message : ""}
            register={register("address")}
          ></CustomTextInput>
        </Box>

        {props.errorMsg ? (
          <Typography color="error" className="error-message">
            {props.errorMsg}
          </Typography>
        ) : (
          <></>
        )}
        {props.responseMsg && !props.errorMsg ? (
          <Typography color="green" className="response-message">
            {props.responseMsg}
          </Typography>
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <Button
            className="submit-button"
            disabled={!props.enableSubmitButton}
            variant="contained"
            type="submit"
            startIcon={<AddBusinessIcon />}
          >
            {props.enableSubmitButton
              ? props.defaultValues?.orgName
                ? "Update"
                : "Create"
              : "..."}
          </Button>
          {props.handleClose ? (
            <Button
              className="close-button"
              disabled={!props.enableSubmitButton}
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={() => props.handleClose?.()}
            >
              Close
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      </form>
    </Grid>
  );
}

export default OrganizationForm;
