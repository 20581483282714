// LoadingSpinner shows a progress spinner while the component or data is loading.

import React from 'react';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const IndefiniteLoadingSpinner: React.FC = () => {
    return (
        <>
            <Stack direction="row" justifyContent="center">
                <CircularProgress size="32px" />
            </Stack>
        </>
    );
};

export default IndefiniteLoadingSpinner;
