import { NavigateFunction } from "react-router-dom";
import { userLogOut } from "../../api/authentication-service";
import { USER_LOGIN_DATA_STORAGE_KEY } from "../../common/const";
import { storeData } from "../../common/utils";

type DrawerClickActionProps = {
  key: string;
  navigate: NavigateFunction;
};
export const DrawerClickAction = ({
  key,
  navigate,
}: DrawerClickActionProps) => {
  switch (key) {
    case "logout": {
      // clear user data
      storeData(USER_LOGIN_DATA_STORAGE_KEY, "");
      userLogOut().then((data) => {
        console.log(data);
        window.location.href = "/login";
      });
      break;
    }

    case "organization": {
      navigate(`/organization`);
      break;
    }

    default: {
      break;
    }
  }
};
