import "./style.scss";

// @ts-ignore
import logo from "../../assets/hea_logo2_500light.png";

import { useEffect, useState } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import CustomTextInput from "../../components/custom-text-input";
import { userLogin } from "../../api/authentication-service";
import { storeData } from "../../common/utils";
import { USER_LOGIN_DATA_STORAGE_KEY } from "../../common/const";
import Layout from "../../components/layout";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type FormInputs = {
  email: string;
  password: string;
};
function Login(props: any) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(
      yup.object({
        email: yup
          .string()
          .email("The email address provided is not valid.")
          .required("Please enter your email address."),
        password: yup.string().required("Please enter your password."),
      }),
    ),
  });

  const [errorMsg, setErrorMsg] = useState("");
  const [enableLogin, setEnableLogin] = useState(true);

  useEffect(() => {
    // clear user data
    storeData(USER_LOGIN_DATA_STORAGE_KEY, "");
  }, []);

  const login = (formData: FormInputs) => {
    if (!enableLogin) return;

    setEnableLogin(false);

    userLogin(formData.email, formData.password).then((resp) => {
      setEnableLogin(true);

      if (resp?.data?.userData) {
        // store user data
        storeData(USER_LOGIN_DATA_STORAGE_KEY, resp.data);
        window.location.href = "/";
      } else {
        if (resp?.error?.message) setErrorMsg(resp?.error?.message);
      }
    });
  };

  return (
    <Layout
      pageName="login"
      disableNavBar={true}
      disableDrawer={true}
      showThemeSwitcher={true}
    >
      <Grid container className="login-page-container">
        <Grid item className="login-input-wrp">
          <Grid container className="logo-wrp">
            <Box
              sx={{
                flex: 1,
                display: { xs: "flex", md: "flex" },
              }}
            >
              <img className="logo" src={logo} alt="HEA" />
            </Box>
          </Grid>

          <form onSubmit={handleSubmit(login)}>
            <Grid item xs={12}>
              <Box className="input-field" sx={{ display: "flex" }}>
                <CustomTextInput
                  label="Email*"
                  icon={<EmailIcon />}
                  maxLength={60}
                  error={errors?.email?.message ? true : false}
                  helperText={
                    errors?.email?.message ? errors.email.message : ""
                  }
                  register={register("email")}
                />
              </Box>
              <Box className="input-field" sx={{ display: "flex" }}>
                <CustomTextInput
                  type="password"
                  label="Password*"
                  icon={<LockIcon />}
                  error={errors?.password?.message ? true : false}
                  helperText={
                    errors?.password?.message ? errors.password.message : ""
                  }
                  register={register("password")}
                />
              </Box>
              <Link className="forgot-password-link" href="">
                I forgot my password
              </Link>

              {errorMsg ? (
                <Typography color="error" className="error-message">
                  {errorMsg}
                </Typography>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                className="login-button"
                disabled={!enableLogin}
                variant="contained"
                type="submit"
              >
                {enableLogin ? "Login" : "..."}
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Login;
