import "./style.scss";
import { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { retrieveUserData } from "../../common/utils";
import { USER_LOGIN_DATA_STORAGE_KEY } from "../../common/const";
import { Grid } from "@mui/material";
import { createOrganization } from "../../api/organization-service";
import OrganizationForm, {
  OrgFormData,
} from "../../components/organization-form";

function CreateOrganization(props: any) {
  useEffect(() => {
    const user = retrieveUserData(USER_LOGIN_DATA_STORAGE_KEY);
    if (!user) window.location.href = "/login";

    console.log("USER LOGGED IN :: ", user?.userData);
  }, []);

  const [enableCreateButton, setEnableCreateButton] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [responseMsg, setResponseMsg] = useState("");

  const SubmitOrganizationData = (formData: OrgFormData) => {
    setErrorMsg("");
    setResponseMsg("");

    setEnableCreateButton(false);

    createOrganization({
      orgName: formData.orgName,
      orgType: formData.orgType,
      domainName: formData.domainName,
      address: formData.address ? formData?.address : "",
    })
      .then((resp) => {
        setEnableCreateButton(true);

        if (resp?.message) {
          setResponseMsg(resp.message);
        } else {
          if (resp?.error?.message) setErrorMsg(resp?.error?.message);
        }
      })
      .catch((er) => {
        console.log("SubmitOrganizationData:", er);
        setEnableCreateButton(true);
      });
  };

  return (
    <Layout pageName="organization">
      <Grid container className="organization-page-container">
        <OrganizationForm
          SubmitOrganizationData={SubmitOrganizationData}
          enableSubmitButton={enableCreateButton}
          errorMsg={errorMsg}
          responseMsg={responseMsg}
        />
      </Grid>
    </Layout>
  );
}

export default CreateOrganization;
