
interface ErrorDetails {
    message: string;
}

// ResultData holds 
type ResultData<T> = {
    ok: boolean;
    data: T | null;
    error: ErrorDetails | null;
}

export class Result<T> {
    ok: boolean;
    data: T | null;
    error: ErrorDetails | null;

    constructor(resultData: ResultData<T>) {
        this.ok = resultData.ok;
        this.data = resultData.data;
        this.error = resultData.error;
    }

    static fromErrorMessage<T>(message: string): Result<T> {
        return new Result<T>({
            ok: false,
            data: null,
            error: {
                message: message,
            },
        });
    }

    static fromData<T>(data: T): Result<T> {
        return new Result<T>({
            ok: true,
            data: data,
            error: null,
        });
    }
}
