
import { AxiosInstance } from 'axios';
import { parseBoolean } from "../utils/utils";
import AxiosMockAdapter from 'axios-mock-adapter';

class MockApi {
    private apiMockAdapter: AxiosMockAdapter;

    constructor(apiClient: AxiosInstance) {
        this.apiMockAdapter = new AxiosMockAdapter(apiClient);
    }

    installHandlers() {
        console.log("Installing mock API handlers");

        this.installLoginHandler();
        this.installGetProgramStateLicenseResearchDataHandler();
        this.installLicenseResearchDataFeedbackUpdateHandler();
        this.installGetLicensureResearchDataListHandler();

        // Match ALL requests
        this.apiMockAdapter.onAny().reply((config) => {
            console.log("Mock API: Unexpected request", config.method, ": ", config.url);
            // Unexpected request, error out
            return [500, {}];
        });
    }

    installLoginHandler() {
        this.apiMockAdapter.onPost("/api/v1/user/login").reply(200, {
            status: "success",
            data: {
                accessToken: "sampleAccessToken123",
                userData: {
                    id: "user123",
                    name: "John Doe",
                    email: "john.doe@example.com",
                },
            },
            error: {
                message: "",
            },
        });
    }

    installGetProgramStateLicenseResearchDataHandler() {
        // Respond after a latency
        const replyLatencyMs = 1000;
        this.apiMockAdapter.onGet(/\/api\/v1\/licensure\/research-data\/next(\?.*)?$/).reply(() => {
            console.log("Mock API: GET /api/v1/licensure/research-data/next");
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([200, {
                        status: "success",
                        data: {
                            id: "123",
                            version: 1,
                            lastUpdatedDate: "2023-01-01",
                            program: {
                                providerOrg: { name: "Rock School", state: { code: "FL" } },
                                programName: "Violin"
                            },
                            stateLicensureStatus: {
                                state: { code: "CA" },
                                meetRequirements: true
                            },
                            referenceData: [
                                { url: "http://example.com/1", note: "Mock Note 1" },
                                { url: "http://example.com/2", note: "Mock Note 2" },
                                { url: "http://example.com/3", note: "Mock Note 3" },
                            ]
                        },
                        error: {
                            message: "",
                        }
                    }]);
                }, replyLatencyMs);
            });
        });
    }

    installLicenseResearchDataFeedbackUpdateHandler() {
        // Respond after a latency
        const replyLatencyMs = 1000;
        this.apiMockAdapter.onPost("/api/v1/licensure/research-data/feedback").reply(() => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([200, {
                        status: "success",
                    }]);
                }, replyLatencyMs);
            });
        });
    }

    installGetLicensureResearchDataListHandler() {
        // Respond after a latency
        const replyLatencyMs = 1000;
        this.apiMockAdapter.onGet(/\/api\/v1\/licensure\/research-data\/list(\?.*)?$/).reply(() => {
            console.log("Mock API: GET /api/v1/licensure/research-data/list");
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve([200, {
                        status: "success",
                        data: {
                            items: [{
                                id: "123",
                                version: 1,
                                lastUpdatedDate: "2023-01-01",
                                program: {
                                    providerOrg: { name: "Rock School", state: { code: "FL" } },
                                    programName: "Violin"
                                },
                                stateLicensureStatus: {
                                    state: { code: "CA" },
                                    meetRequirements: true
                                },
                                referenceData: [
                                    { url: "http://example.com/1", note: "Mock Note 1" },
                                    { url: "http://example.com/2", note: "Mock Note 2" },
                                    { url: "http://example.com/3", note: "Mock Note 3" },
                                ]
                            }],
                        },
                        error: {
                            message: "",
                        }
                    }]);
                }, replyLatencyMs);
            });
        });
    }
}

function initMockApi(apiClient: AxiosInstance) {
    if (parseBoolean(process.env.REACT_APP_USE_MOCK_API)) { 
        mockApi = new MockApi(apiClient);
        mockApi.installHandlers();
    }
}

var mockApi: MockApi

export { initMockApi };
