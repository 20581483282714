import { getData, postData, ApiResponse, createApiRequestData } from "./api";

import { ProgramStateLicensureStatusRecord, LicensureResearchDataRecord, LicensureResearchDataListRecord } from "./models";
import { LicensureResearchDataReviewFeedback } from "./models";
import { Result } from "./result";

// TODO: 
type ProgramStateLicensureStatusDTO = ProgramStateLicensureStatusRecord

/**
 * Fetches the next Program State Licensure Status research data to review.
 *
 * @param resumeKey - The key to resume fetching data from a specific point. If null or empty, fetching starts from the beginning.
 * @returns A promise that resolves to a Result containing the ProgramStateLicensureStatusRecord.
 *
 * @throws Will return a Result with an error message if the fetch operation fails.
 */
export const retrieveNextLicensureDataToReview = async (resumeKey: string | null): Promise<Result<ProgramStateLicensureStatusRecord>> => {
    try {
        let url = "/api/v1/licensure/research-data/next";

        if (resumeKey && resumeKey !== "") {
            url += `?resumeKey=${resumeKey}`;
        }

        const apiResp = ApiResponse.fromApiResponse<ProgramStateLicensureStatusDTO>(await getData<ApiResponse<ProgramStateLicensureStatusDTO>>(url));

        if (!apiResp.isSuccess()) {
            return Result.fromErrorMessage<ProgramStateLicensureStatusRecord>(apiResp.error!.message);
        }

        // TODO: Handle not found
        // TODO: Handle other errors
        return Result.fromData<ProgramStateLicensureStatusRecord>(newProgramStateLicensureStatusRecordFromDTO(apiResp!.data!));
    } catch (error) {
        console.error("Error fetching data:", error);
        return Result.fromErrorMessage<ProgramStateLicensureStatusRecord>(String(error));
    }
};

function newProgramStateLicensureStatusRecordFromDTO(apiRespDTO: ProgramStateLicensureStatusDTO): ProgramStateLicensureStatusRecord {
    return new ProgramStateLicensureStatusRecord(
        apiRespDTO.id,
        new Date(apiRespDTO.lastUpdatedDate),
        apiRespDTO.version,
        apiRespDTO.program,
        apiRespDTO.stateLicensureStatus,
        apiRespDTO.referenceData,
    );
}

export const updateLicensureResearchDataReviewFeedback = async(feedback: LicensureResearchDataReviewFeedback): Promise<Result<void>> => {
    try {
        const url = "/api/v1/licensure/research-data/feedback";

        const apiResp = ApiResponse.fromApiResponse<void>(await postData<ApiResponse<void>>(url, createApiRequestData(feedback)));

        if (!apiResp.isSuccess()) {
            return Result.fromErrorMessage<void>(apiResp.error!.message);
        }

        return Result.fromData<void>(undefined);
    } catch (error) {
        console.error("Error updating feedback:", error);
        return Result.fromErrorMessage<void>(String(error));
    }
}


// TODO:

type LicensureResearchDataListDTO = LicensureResearchDataListRecord;

export const retrieveLicensureResearchDataList = async (limit: number | null): Promise<Result<LicensureResearchDataListRecord>> => {
    try {
        let url = "/api/v1/licensure/research-data/list?limit=25";

        if (limit && limit !== 0) {
            url += `?limit=${limit}`;
        }

        const apiResp = ApiResponse.fromApiResponse<LicensureResearchDataListDTO>(await getData<ApiResponse<LicensureResearchDataListDTO>>(url));

        if (!apiResp.isSuccess()) {
            return Result.fromErrorMessage<LicensureResearchDataListRecord>(apiResp.error!.message);
        }

        // TODO: Handle not found
        // TODO: Handle other errors
        return Result.fromData<LicensureResearchDataListRecord>(newLicensureResearchDataListRecordFromDTO(apiResp!.data!));
    } catch (error) {
        console.error("Error fetching data:", error);
        return Result.fromErrorMessage<LicensureResearchDataListRecord>(String(error));
    }
};


type LicensureResearchDataDTO = LicensureResearchDataRecord; // TODO:

function newLicensureResearchDataRecordFromDTO(apiRespDTO: LicensureResearchDataDTO): LicensureResearchDataRecord {
    return new LicensureResearchDataRecord(
        apiRespDTO.id,
        new Date(apiRespDTO.lastUpdatedDate),
        apiRespDTO.version,
        apiRespDTO.program,
        apiRespDTO.stateLicensureStatus
    );
}

function newLicensureResearchDataListRecordFromDTO(apiRespDTO: LicensureResearchDataListDTO): LicensureResearchDataListRecord {
    const items = apiRespDTO.items.map(item => newLicensureResearchDataRecordFromDTO(item));
    return new LicensureResearchDataListRecord(items);
}