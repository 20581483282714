import "./style.scss";
import React, { useState } from "react";
import { TextField, Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { UseFormRegisterReturn } from "react-hook-form";

interface CustomTextInputProps {
  id?: string;
  type?: string;
  label: string;
  required?: boolean;
  maxLength?: number;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  icon?: React.ReactNode;
  helperText?: string;
  error?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  register?: UseFormRegisterReturn;
}

const CustomTextInput: React.FC<CustomTextInputProps> = ({
  id,
  type,
  label,
  required,
  maxLength,
  fullWidth,
  multiline,
  rows,
  icon,
  helperText,
  error,
  register,
  onChange,
  onClick,

  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        width: "100%",
      }}
    >
      <div
        className={
          "input-icon " + (error && helperText?.length ? "field-error" : "")
        }
      >
        {icon}
      </div>
      <TextField
        id={id}
        label={label}
        type={
          type && (!type || (showPassword && type === "password"))
            ? "text"
            : type
        }
        variant="standard"
        sx={{ width: "100%" }}
        onClick={onClick}
        onKeyDown={() => (props?.onKeyDown ? props.onKeyDown : "")}
        required={required}
        inputProps={{ maxLength: maxLength }}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        error={error}
        helperText={helperText ? helperText : ""}
        {...register}
        {...props}
      />

      {type && type === "password" ? (
        <div
          className="show-password-icon"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </div>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default CustomTextInput;
